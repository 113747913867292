<template>
  <div id="client-dashboard">
    <header>
      <div>
        <i class="fas fa-home"></i>
        <label v-if="client != null">Witaj {{ client.name }}</label>
      </div>

      <i class="fas fa-power-off" @click="logout"></i>
    </header>

    <main>
      <div class="add-user box">
        <h2>DODAJ GRACZA</h2>
        <form @submit.prevent="addPlayer">
          <input type="text" placeholder="Imię gracza / Nazwa zespołu" v-model="firstname" required>
          <input type="email" placeholder="E-mail gracza" v-model="email" required>
          <div class=games-list>
            <h3>Przydziel gry:</h3>
            <div v-for="(game, index) in sortGames(games)" :key="index">
              <input type="checkbox" v-model="game.active"> <span>{{ game.name }}</span>
            </div>
          </div>
          <button type="submit">Dodaj gracza</button>
        </form>
      </div>
      <div class="users box">
        <h2>LISTA GRACZY</h2>
        <div class="user" v-for="(player, index) in players" :key="index">
          <div class="user-info">
            <div>
              <strong>{{ player.name }}</strong> ({{ player.email }})
            </div>
            <div>
              <i class="fas fa-key"></i> <strong>PIN: {{ player.pin }}</strong>
            </div>
          </div>
          
          <div class="games-list">
            <h3>Przydzielone gry:</h3>
            <div>
              <div v-for="(game, index) in sortGames(player.games)" :key="index">
                <div :class="{'game-box': true, 'disable-game': game.active == false}">
                  <div class="game-box-header">
                    <input type="checkbox" v-model="game.active"> <span>{{ game.name }}</span>
                  </div>

                  <div class="game-box-content">
                    <div class="game-box-prop" v-if="game.startDateTime && !game.endDateTime">
                      <i class="fas fa-clock"></i> <strong>CZAS GRY:</strong> <strong>{{ setTimer(game.startDateTime.toDate()) }}</strong>
                    </div>
                    <div class="game-box-prop" v-if="game.endDateTime">
                      <i class="fas fa-clock"></i> <strong>CZAS UKOŃCZENIA:</strong> <strong>{{ getEndTime(game.startDateTime.toDate(), game.endDateTime.toDate()) }}</strong>
                    </div>
                    <div class="game-box-prop" v-if="game.userPoints">
                      <i class="fas fa-star"></i> <strong>PUNKTY:</strong> <strong>{{ game.userPoints }}</strong>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
            
            <div class="games-list-btns">
              <button style="background:#42b983;" @click="updateUser(player.id, index)">Aktualizuj</button>
              <button class="delete-btn" @click="deleteUser(player)">Usuń gracza</button>
            </div>
          </div>
        </div>
      </div>

    </main>
  </div>
</template>

<script>
import firebase from '@/firebase';

export default {
  data() {
    return {
      players: [],
      games: {},
      firstname: "",
      email: "",
      clients: [],
      client: null,
    }
  },
  mounted() {
    const db = firebase.firestore();
    db.collection("clients").get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        this.clients.push(Object.assign({id: doc.id}, doc.data()));
      });

      firebase.auth().onAuthStateChanged(user =>  {
        let foundClient = null;
        if (user)
          foundClient = this.clients.find(c => c.id == user.uid);
          // console.log(foundClient)
        
        if (foundClient) {
          this.client = foundClient;
          this.$store.commit('SET_USER', user);
          this.fetchGames();
          this.fetchPlayers();
        } else {
          this.$router.push('/client');
        }
      });
    });
  },
  methods: {
    logout() {
      firebase.auth().signOut().then(() => {
        this.$router.push('/client');
        this.$store.commit('LOGOUT_USER');
      });
    },
    fetchGames() {
      this.games = this.client.games;
    },
    fetchPlayers() {
      const db = firebase.firestore();

      db.collection('users').where('clientId', '==', this.client.id).onSnapshot(querySnapshot => {
        querySnapshot.docChanges().forEach(change => {
          if (change.type === 'added') {
            // console.log('Added: ', change.doc.data());
            let newPlayer = Object.assign({id: change.doc.id}, change.doc.data());
            this.players.push(newPlayer);
          }
          if (change.type === 'modified') {
            // console.log('Modified: ', change.doc.data());
            this.players.splice(this.players.findIndex(i => i.id === change.doc.id), 1);
            let newPlayer = Object.assign({id: change.doc.id}, change.doc.data());
            this.players.push(newPlayer);
          }
          if (change.type === 'removed') {
            // console.log('Removed: ', change.doc.data());
            this.players.splice(this.players.findIndex(i => i.id === change.doc.id), 1);
          }
        });

        // Usuwanie gier przyporządkowanych do graczy, które zostały usunięte z listy gier klienta
        let gameNames = Object.keys(this.client.games);

        this.players.forEach(async player => {
          let userGameNames = Object.keys(player.games)
          
          // Gdy usuwamy grę z listy klienta usuń gry z list graczy
          let diff = userGameNames.filter(x => !gameNames.includes(x));
          // console.log(diff);
          diff.forEach(el => {
            delete player.games[el];
            // player.games[el].active = false;
          });

          // Gdy dodajemy grę do listy klienta dodaj możliwość aktywowania gry istniejącym graczom
          let diff2 = gameNames.filter(x => !userGameNames.includes(x));
          // console.log(diff2);
          diff2.forEach(el => {
            player.games[el] = this.games[el];
          });

          await db.collection('users').doc(player.id).update({
            'games': player.games,
          });
        });
      });
    },
    /*
    Dodawanie i usuwanie gracza polega na usunięciu/dodaniu danych do Firestore.
    Dodatkowo zostały utworzone dwie funkcje w Cloud Functions które reagują na zmiany w bazie danych:
    - kiedy zostanie dodany nowy rekord w tabeli users -> zostaje utworzony użytkownik
    - kiedy zostanie usunięty rekord z tabeli users -> zostaje usunięty użytkownik
    */
    async addPlayer() {
      /*
      W momencie startu gry przez gracza (czyli zalogowania się i wybrania gry) powinno dodatkowo dodawać pola:
      - ilość logowań
      - czas startu gry (dla celów obliczania czasu)
      */

      // POMYSŁ: Fajnie żeby sam email + imię trafiało jeszcze do osobnej tabeli w bazie dla celów przyszłego newslettera

      let password = "";

      for (let i = 0; i < 6; i++) {
        password += String(this.getRandomInt(0, 9));
      }

      const db = firebase.firestore();
      await db.collection('users').doc().set({
        name: this.firstname,
        email: this.email,
        pin: password,
        games: this.games,
        clientId: this.client.id,
      }, { merge: true });

      alert('Dodano nowe gracza. Wygenerowany pin: ' + password);
      this.firstname = "";
      this.email = "";
    },
    async deleteUser(player) {
      const db = firebase.firestore();
      await db.collection('users').doc(player.id).delete();
    },
    async updateUser(uid, index) {
      const db = firebase.firestore();
      await db.collection('users').doc(uid).update({
        'games': this.players[index].games,
      });
    },
    getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    },
    sortGames(games) {
      let arr = Object.values(games);
      return arr.sort((a,b) => (a.url > b.url) ? 1 : ((b.url > a.url) ? -1 : 0));
    },
    setTimer(startTime) {
      let timer = null;
      
      //setInterval((timer) => {
        let now = new Date();
        let diffDate = now - startTime.getTime();

        let ms = diffDate % 1000;
        diffDate = (diffDate - ms) / 1000;
        let sec = diffDate % 60;
        diffDate = (diffDate - sec) / 60;
        let min = diffDate % 60;
        let hrs = (diffDate - min) / 60;

        if (sec < 10) sec = "0" + sec;
        if (min < 10) min = "0" + min;
        if (hrs < 10) hrs = "0" + hrs;

        timer = hrs + ":" + min + ":" + sec;
      //}, 1000);
      
      return timer;
    },
    getEndTime(startTime, endTime) {
      let timer = null;
      
      let diffDate = endTime.getTime() - startTime.getTime();

      let ms = diffDate % 1000;
      diffDate = (diffDate - ms) / 1000;
      let sec = diffDate % 60;
      diffDate = (diffDate - sec) / 60;
      let min = diffDate % 60;
      let hrs = (diffDate - min) / 60;

      if (sec < 10) sec = "0" + sec;
      if (min < 10) min = "0" + min;
      if (hrs < 10) hrs = "0" + hrs;

      timer = hrs + ":" + min + ":" + sec;
      
      return timer;
    }
  }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@200;400;700&display=swap');

header, main, input {
  font-family: 'Source Sans Pro', sans-serif;
}
header {
  width: 100%;
  height: 50px;
  background: linear-gradient(45deg, #0080bb, #00c1cf);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  color: white;
  /* box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.25); */
  font-size: 1.5rem;
  z-index: 10;
}
header > div {
  display: flex;
  align-items: center;
}
header label {
  color: #fff;
  text-decoration: none;
  font-size: 1.2rem;
  margin-left: .5rem;
  font-weight: bold;
}
main {
  margin-top: 50px;
  padding: 1rem;
  background: #fff;
  min-height: calc(100vh - 50px);
  display: flex;
}
.box {
  min-height: 100px;
  width: 100%;
  background: linear-gradient(#f8f8f8, #fff);
  margin: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
}
h2 {
  margin: 0 0 1rem 0;
  text-transform: uppercase;
}
h3 {
  margin-bottom: 0.5rem;
  text-transform: uppercase;
}
form > * {
  margin: .5rem 0;
}
input {
  padding: .5rem;
  border-radius: .25rem .25rem 0 0;
  border: none;
  border-bottom: 2px solid #0080bb;
}
.add-user {
  flex-basis: 40%;
}
.add-user form {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.games-list {
  text-align: left;
  margin-top: 1rem;
}
.games-list-btns {
  display: flex !important;
  justify-content: space-between;
}
button {
  box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.25);
  background: linear-gradient(45deg, #0080bb, #00c1cf);
  transition: all 0.3s ease-in-out;
  margin-bottom: 0;
}
button:hover {
  animation: unset;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.25);
}
.users .user {
  background: #fff;
  padding: 1rem;
  width: 100%;
  text-align: left;
  border-radius: .25rem .25rem 0 0;
  border: none;
  border-bottom: 2px solid #0080bb;
  margin-bottom: 1rem;
}
.users .user .user-info {
  display: flex;
  justify-content: space-between;
}

.user .games-list > div {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 1rem;
}

.game-box {
  box-shadow:0 0 10px 0 rgba(0,0,0,.1);
}
.game-box-header {
  background: linear-gradient(45deg, #00c1cf, #0080bb);
  color: #fff;
  padding: 0.5rem;
  font-weight: bold;
  border-radius: .25rem .25rem 0 0;
}
.game-box-content {
  display:flex;
}
.game-box-prop {
  margin:.5rem;
  padding:.5rem;
  background:#f2f2f2;
}

.delete-btn {
  background: tomato;
}

.disable-game {
  opacity: 0.5;
}

@media screen and (max-width: 768px) {
  main {
    flex-direction: column;
  }
}
</style>